import { bindable, inject }                                from 'aurelia-framework';
import { AppContainer }                                    from 'resources/services/app-container';
import { BaseListViewModel }                               from 'base-list-view-model';
import { ConfirmTechnicalResponsibilityDeclinationDialog } from 'resources/elements/html-elements/dialogs/confirm-technical-responsibility-declination-dialog';
import { DialogService }                                   from 'aurelia-dialog';
import { Downloader }                                      from 'resources/services/downloader';
import { LiftsRepository }                                 from 'modules/lifts/lifts/services/repository';
import { LiftMaintenanceCompaniesRepository }              from 'modules/lifts/lifts/maintenance-companies/services/repository';
import { LiftStatus }                                      from 'modules/management/models/lift-status';
import { FilterFormSchema }                                from 'modules/lifts/lifts/filter-form-schema';
import { ViewLiftCertificates }                            from 'modules/lifts/lifts/certificates/index';
import { ViewLiftChecklist }                               from 'modules/lifts/lifts/checklists/index';
import { ViewLiftHistory }                                 from 'modules/lifts/lifts/history/index';

@inject(AppContainer, DialogService, Downloader, LiftsRepository, LiftMaintenanceCompaniesRepository, FilterFormSchema)
export class ListLifts extends BaseListViewModel {

    listingId = 'lifts-lifts-listing';

    @bindable headerTitle    = 'listing.lifts.lifts';
    @bindable newRecordRoute = 'lifts.lifts.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param repository
     * @param liftMaintenanceCompaniesRepository
     * @param filterFormSchema
     */
    constructor(appContainer, dialogService, downloader, repository, liftMaintenanceCompaniesRepository, filterFormSchema) {
        super(appContainer);

        this.dialogService                      = dialogService;
        this.downloader                         = downloader;
        this.repository                         = repository;
        this.liftMaintenanceCompaniesRepository = liftMaintenanceCompaniesRepository;
        this.filterFormSchema                   = filterFormSchema;
        this.newRecordRoute                     = appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.create']) ? this.newRecordRoute : null;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.filterModel  = this.filterFormSchema.model(this);
        this.filterSchema = this.filterFormSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:           'resource.lifts.lift',
            repository:         this.repository,
            show:               {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']),
            },
            edit:               {
                action:  true,
                visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.edit']),
            },
            destroy:            {
                action:  true,
                visible: (row) => this.canDelete(row),
            },
            actions:            [
                {
                    icon:    'icon-user-cancel',
                    tooltip: 'form.button.decline-technical-responsibility',
                    action:  (row) => this.declineTechnicalResponsibility(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view'])
                        && row.status.id !== LiftStatus.AWAITING_VALIDATION
                        && row.current_maintenance_company_name !== null
                        && this.appContainer.authenticatedUser.belongsToMaintenanceCompany(),
                },
                {
                    icon:    'icon-stack-check',
                    tooltip: 'form.button.checklist',
                    action:  (row) => this.openLiftChecklist(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']) && row.status.id !== LiftStatus.AWAITING_VALIDATION,
                },
                {
                    icon:    'icon-history',
                    tooltip: 'form.button.history',
                    action:  (row) => this.openLiftHistory(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']) && row.status.id !== LiftStatus.AWAITING_VALIDATION,
                },
                {
                    icon:    'icon-certificate',
                    tooltip: 'form.button.certificates',
                    action:  (row) => this.openLiftCertificates(row),
                    visible: (row) => this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.view']) && row.status.id !== LiftStatus.AWAITING_VALIDATION,
                },
            ],
            options:            [
                {
                    label:  'form.button.export-to-excel',
                    icon:   'icon-file-excel',
                    action: () => this.exportListing('ascensores'),
                },
            ],
            buttons:            [],
            actionsContextMenu: true,
            selectable:         true,
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    data:   'code',
                    name:   'lifts.code',
                    title:  'form.field.lift-number',
                    type:   'link',
                    action: (row) => this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: row.id }),
                },
                {
                    data:  'lift_type_name',
                    name:  'lift_types.name',
                    title: 'form.field.lift-type',
                },
                {
                    data:  'address',
                    name:  'lifts.address',
                    title: 'form.field.address',
                },
                {
                    data:  'parish_name',
                    name:  'parishes.name',
                    title: 'form.field.parish',
                },
                {
                    data:  'current_owner_name',
                    name:  'owner_data.name',
                    title: 'form.field.owner/administrator',
                },
                {
                    data:  'status',
                    name:  'lifts.status_id',
                    title: 'form.field.status',
                    type:  'label',
                },
            ],
        };
    }

    /**
     * Opens the lift checklist modal
     *
     * @param row
     */
    openLiftChecklist(row) {
        this.dialogService.open({ viewModel: ViewLiftChecklist, model: row });
    }

    /**
     * Opens the lift history modal
     *
     * @param row
     */
    openLiftHistory(row) {
        this.dialogService.open({ viewModel: ViewLiftHistory, model: row });
    }

    /**
     * Opens the lift certificates modal
     *
     * @param row
     */
    openLiftCertificates(row) {
        this.dialogService.open({ viewModel: ViewLiftCertificates, model: row });
    }

    /**
     * Checks if the given row can be deleted
     *
     * @param row
     */
    canDelete(row) {
        if (this.appContainer.authenticatedUser.belongsToInstallingCompany()) {
            return row.status.id === LiftStatus.AWAITING_VALIDATION;
        }

        return this.appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.delete']);
    }

    /**
     * Opens the technical responsibility declination confirmation dialog
     *
     * @param row
     */
    declineTechnicalResponsibility(row) {
        this.dialogService
            .open({ viewModel: ConfirmTechnicalResponsibilityDeclinationDialog, model: row })
            .whenClosed((dialogResponse) => {
                if (! dialogResponse.wasCancelled) {
                    this.liftMaintenanceCompaniesRepository
                        .unassign(row.current_maintenance_company_id)
                        .then((response) => {
                            if (response.status === true) {
                                this.appContainer.notifier.successNotice(response.message);
                                this.datatable.instance.reload();
                            } else {
                                this.appContainer.notifier.dangerNotice(response.message);
                            }
                        });
                }
            });
    }

}
