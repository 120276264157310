import { BaseModel } from 'modules/core/models/base-model';

export class MaintenanceLog extends BaseModel {

    lift_id     = null;
    type_id     = null;
    date        = null;
    description = null;

}
