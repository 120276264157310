import { inject }                        from 'aurelia-framework';
import { LiftsRepository }               from 'modules/lifts/lifts/services/repository';
import { MaintenanceLog }                from 'modules/lifts/models/maintenance-log';
import { MaintenanceLogTypesRepository } from 'modules/management/maintenance-log-types/services/repository';
import moment                            from 'moment';

@inject(LiftsRepository, MaintenanceLogTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        date: moment().format('YYYY-MM-DD'),
    };

    /**
     * Constructor
     *
     * @param liftsRepository
     * @param maintenanceLogTypesRepository
     */
    constructor(liftsRepository, maintenanceLogTypesRepository) {
        this.liftsRepository               = liftsRepository;
        this.maintenanceLogTypesRepository = maintenanceLogTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {MaintenanceLog}
     */
    model() {
        let model = new MaintenanceLog();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.lift_id = {
            type:           'select2',
            key:            'lift_id',
            label:          'form.field.lift',
            size:           12,
            options:        [],
            remoteSource:   this.liftsRepository.all.bind(this.liftsRepository),
            processResults: (row) => {
                row.name = `${row.code} - ${row.address}`;

                return row;
            },
        };

        this.date = {
            type:            'material-ui-date-picker',
            key:             'date',
            label:           'form.field.date',
            size:            3,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.log-type',
            size:         9,
            options:      [],
            remoteSource: this.maintenanceLogTypesRepository.active.bind(this.maintenanceLogTypesRepository),
        };

        this.description = {
            type:  'textarea',
            key:   'description',
            label: 'form.field.description',
            size:  12,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.lift_id],
            [this.date, this.type_id],
            [this.description],
            [this.buttons],
        ];
    }

}
