import { BaseSubRepository } from 'modules/core/services/base-subrepository';

export class LiftMaintenanceCompaniesRepository extends BaseSubRepository {

    baseUrl  = 'lifts/lifts';
    resource = 'maintenance-companies';

    /**
     * Unassigns the maintenance company from the lift
     *
     * @param id
     *
     * @returns {*}
     */
    unassign(id) {
        return this.httpClient.patch(`${this.baseUrl}/${this.resource}/${id}/unassign`);
    }

}
