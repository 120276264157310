import { bindable, bindingMode, customElement, inject } from 'aurelia-framework';
import { AppContainer }                                 from 'resources/services/app-container';
import { BaseViewModel }                                from 'base-view-model';
import { DialogService }                                from 'aurelia-dialog';
import { EditOwnerModal }                               from 'modules/entities/owners/edit-modal/index';
import { FormSchema }                                   from 'modules/lifts/lifts/owners/form-schema';
import { LiftOwnersRepository }                         from 'modules/lifts/lifts/owners/services/repository';

@customElement('lift-owners')
@inject(AppContainer, DialogService, FormSchema, LiftOwnersRepository)
export class ListLiftOwners extends BaseViewModel {

    listingId = 'lifts-lift-owners-listing';
    formId    = 'lifts-lift-owners-creation-form';

    @bindable({defaultBindingMode: bindingMode.twoWay}) lift;
    @bindable({defaultBindingMode: bindingMode.twoWay}) readonly = false;

    repository;
    datatable;
    creationSchemaVisible = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
            'lifts.lifts.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
        this.schema       = this.formSchema.schema(this);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            resource:        'resource.entities.owner',
            repository:      {
                search:          (criteria) => this.repository.search(this.lift.id, criteria),
                destroy:         (id) => this.repository.destroy(id),
                destroySelected: (ids) => this.repository.destroySelected(ids),
            },
            edit:            {
                action:  (row) => this.editOwner(row),
                visible: (row) => this.appContainer.authenticatedUser.can(['entities.owners.manage', 'entities.owners.edit']) && !row.unassigned_at && !this.readonly,
            },
            destroy:         !this.readonly,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [
                {
                    label:     'form.button.create-new',
                    icon:      'icon-plus3',
                    className: 'btn bg-teal',
                    color:     'success',
                    visible:   !this.readonly,
                    action:    () => this.creationSchemaVisible = true,
                },
            ],
            selectable:      false,
            sorting:         {
                column:    1,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'entity',
                    name:  'entity_data.name',
                    title: 'form.field.designation',
                },
                {
                    data:            'assigned_at',
                    name:            'lift_owners.assigned_at',
                    title:           'form.field.associated-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:            'unassigned_at',
                    name:            'lift_owners.unassigned_at',
                    title:           'form.field.disassociated-at',
                    valueConverters: [
                        {
                            name: 'dateFormat',
                        },
                    ],
                },
                {
                    data:       'alerts',
                    title:      '',
                    type:       'stoplight',
                    orderable:  false,
                    searchable: false,
                    color:      (row) => !row.unassigned_at ? 'text-success' : 'text-danger',
                },
            ],
        };
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.repository
            .create(this.lift.id, this.model)
            .then(response => {
                this.alert = this.alertMessage(response.status, response.message, response.errors, false);

                if (response.status === true) {
                    this.datatable.instance.reload();
                    this.resetForm();
                }
            })
            .catch(console.log.bind(console));
    }

    /**
     * Resets form fields
     */
    resetForm(nullifyAlert = true) {
        this.resetModelValues()
            .then(() => {
                // publishes `form-reseted` event
                this.appContainer.eventAggregator.publish('form-reseted', this.formId);

                if (nullifyAlert) {
                    this.alert = null;
                }
            });
    }

    /**
     * Resets model to initial values
     *
     * @returns {Promise}
     */
    resetModelValues() {
        return new Promise((resolve, reject) => {
            this.model.assign(this.initialModel);

            resolve(true);
            reject(new Error('Error'));
        });
    }

    /**
     * Opens the owner edition modal
     *
     * @param row
     */
    editOwner(row) {
        this.dialogService
            .open({ viewModel: EditOwnerModal, model: { id: row.entity_id } })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

}
