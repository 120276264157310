import { BaseRepository } from 'modules/core/services/base-repository';

export class LiftsRepository extends BaseRepository {

    baseUrl = 'lifts/lifts';

    /**
     * Fetches lift details
     *
     * @param id
     *
     * @returns {*}
     */
    details(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/details`);
    }

    /**
     * Finds a lift by its code
     *
     * @param code
     *
     * @returns {*}
     */
    findByCode(code) {
        return this.httpClient.get(`${this.baseUrl}/code/${code}`);
    }

    /**
     * Returns the given record's timeline
     *
     * @param id
     *
     * @returns {*}
     */
    timeline(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/timeline`);
    }

    /**
     * Returns the given record's certificates
     *
     * @param id
     *
     * @returns {*}
     */
    certificates(id) {
        return this.httpClient.get(`${this.baseUrl}/${id}/certificates`);
    }

    /**
     * Validates the lift
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    validate(id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}/validate`, data);
    }

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}
