import { inject }           from 'aurelia-framework';
import { CreateOwnerModal } from 'modules/entities/owners/create-modal/index';
import { DialogService }    from 'aurelia-dialog';
import { LiftOwner }        from 'modules/lifts/models/lift-owner';
import { OwnersRepository } from 'modules/entities/owners/services/repository';

@inject(OwnersRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param ownersRepository
     * @param dialogService
     */
    constructor(ownersRepository, dialogService) {
        this.ownersRepository = ownersRepository;
        this.dialogService    = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LiftOwner}
     */
    model() {
        let model = new LiftOwner();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.owner_id = {
            type:           'select2',
            key:            'owner_id',
            label:          'form.field.owner/administrator',
            size:           12,
            options:        [],
            remoteSource:   this.ownersRepository.active.bind(this.ownersRepository),
            processResults: (row) => {
                row.name = row.address ? `${row.name} - ${row.address}` : row.name;

                return row;
            },
            inputGroup:     {
                position: 'right',
                button:   {
                    title:   'form.button.create-new',
                    action:  () => this.createNewOwner(viewModel),
                    icon:    { class: 'icon-plus3' },
                    visible: viewModel.appContainer.authenticatedUser.can(['entities.owners.manage', 'entities.owners.create']),
                },
            },
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions: [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.owner_id],
            [this.buttons],
        ];
    }

    /**
     * Opens the owner creation modal
     *
     * @param viewModel
     */
    createNewOwner(viewModel) {
        this.dialogService
            .open({ viewModel: CreateOwnerModal })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.owner_id.instance.fetchData().then(() => viewModel.model.owner_id = response.output);
                }
            });
    }

}
