import { BaseModel } from 'modules/core/models/base-model';

export class NewLiftCode extends BaseModel {

    district_id      = null;
    county_id        = null;
    parish_id        = null;
    code_incrementer = null;

}
