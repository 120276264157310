import { BaseModel }  from 'modules/core/models/base-model';
import { LiftStatus } from 'modules/management/models/lift-status';

export class Lift extends BaseModel {

    lift_type_id             = null;
    building_type_id         = null;
    applicable_regulation_id = null;
    installing_company_id    = null;
    maintenance_company_id   = null;
    owner_id                 = null;
    code                     = null;
    parish_id                = null;
    address                  = null;
    zip_code                 = null;
    locality                 = null;
    capacity                 = null;
    person_capacity          = null;
    width                    = null;
    unevenness               = null;
    velocity                 = null;
    certificate_issued_at    = null;
    inspection_periodicity   = null;
    certificate_deadline     = null;
    number_of_floors         = null;
    number_of_accesses       = null;
    latitude                 = null;
    longitude                = null;
    status_id                = null;
    timeline                 = [];

    /**
     * Checks whether the lift is awaiting for validation
     *
     * @returns {boolean}
     */
    isAwaitingValidation() {
        return this.status_id === LiftStatus.AWAITING_VALIDATION;
    }

    /**
     * Checks whether the lift is approved
     *
     * @returns {boolean}
     */
    isApproved() {
        return this.status_id === LiftStatus.APPROVED;
    }

    /**
     * Checks whether the lift is rejected
     *
     * @returns {boolean}
     */
    isRejected() {
        return this.status_id === LiftStatus.REJECTED;
    }

    /**
     * Checks whether the lift is sealed
     *
     * @returns {boolean}
     */
    isSealed() {
        return this.status_id === LiftStatus.SEALED;
    }

}
