import { bindable, inject }           from 'aurelia-framework';
import { AppContainer }               from 'resources/services/app-container';
import { BaseFormViewModel }          from 'base-form-view-model';
import { FormSchema }                 from 'modules/lifts/maintenance-logs/form-schema';
import { MaintenanceLogsRepository }  from 'modules/lifts/maintenance-logs/services/repository';

@inject(AppContainer, FormSchema, MaintenanceLogsRepository)
export class CreateMaintenanceLog extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.create-new-record';
    @bindable formId      = 'lifts.maintenance-logs.creation-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.maintenance-logs.manage',
            'lifts.maintenance-logs.create',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.repository
            .create(this.model)
            .then(response => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    this.resetForm();
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            });
    }

}
