import { bindable, inject }              from 'aurelia-framework';
import { AppContainer }                  from 'resources/services/app-container';
import { BaseFormViewModel }             from 'base-form-view-model';
import { DialogService }                 from 'aurelia-dialog';
import { LiftsRepository }               from 'modules/lifts/lifts/services/repository';
import { LiftFilesRepository }           from 'modules/lifts/lifts/services/files-repository';
import { LiftStatus }                    from 'modules/management/models/lift-status';
import { ViewControllingEntityDetails }  from 'modules/entities/controlling-entities/details/index';
import { ViewInspectingEntityDetails }   from 'modules/entities/inspecting-entities/details/index';
import { ViewInstallingCompanyDetails }  from 'modules/entities/installing-companies/details/index';
import { ViewLiftCertificates }          from 'modules/lifts/lifts/certificates/index';
import { ViewLiftHistory }               from 'modules/lifts/lifts/history/index';
import { ViewMaintenanceCompanyDetails } from 'modules/entities/maintenance-companies/details/index';
import { ViewManagingEntityDetails }     from 'modules/entities/managing-entities/details/index';
import { ViewOwnerDetails }              from 'modules/entities/owners/details/index';

@inject(AppContainer, DialogService, LiftsRepository, LiftFilesRepository)
export class ViewLift extends BaseFormViewModel {

    @bindable headerTitle     = 'form.title.view-record';
    @bindable editRecordRoute = 'lifts.lifts.edit';
    @bindable formId          = 'lifts-lifts-view-form';

    markers             = [];
    previousInstruction = null;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param repository
     * @param filesRepository
     */
    constructor(appContainer, dialogService, repository, filesRepository) {
        super(appContainer);

        this.dialogService   = dialogService;
        this.repository      = repository;
        this.filesRepository = filesRepository;
        this.editRecordRoute = appContainer.authenticatedUser.can(['lifts.lifts.manage', 'lifts.lifts.edit']) ? this.editRecordRoute : null;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.previousInstruction = this.appContainer.router.currentInstruction;

        return this.repository.details(params.id).then((response) => {
            this.lift = response;

            this.markers.push({
                latitude:  this.lift.latitude,
                longitude: this.lift.longitude,
            });
        });
    }

    /**
     * Returns to the previous page
     */
    back() {
        this.appContainer.router.navigateToRoute(this.previousInstruction.config.name, this.previousInstruction.params);
    }

    /**
     * Navigates the user to the edit form
     */
    edit() {
        this.appContainer.router.navigateToRoute(this.editRecordRoute, { id: this.lift.id });
    }

    /**
     * Opens the controlling entity details modal
     */
    viewControllingEntity() {
        if (this.lift.controlling_entity_id) {
            this.dialogService.open({ viewModel: ViewControllingEntityDetails, model: this.lift.controlling_entity_id });
        }
    }

    /**
     * Opens the inspecting entity details modal
     */
    viewInspectingEntity() {
        if (this.lift.last_inspecting_entity_id) {
            this.dialogService.open({ viewModel: ViewInspectingEntityDetails, model: this.lift.last_inspecting_entity_id });
        }
    }

    /**
     * Opens the installing company details modal
     */
    viewInstallingCompany() {
        if (this.lift.installing_company_id) {
            this.dialogService.open({ viewModel: ViewInstallingCompanyDetails, model: this.lift.installing_company_id });
        }
    }

    /**
     * Opens the maintenance company details modal
     */
    viewMaintenanceCompany() {
        if (this.lift.current_maintenance_company_id) {
            this.dialogService.open({ viewModel: ViewMaintenanceCompanyDetails, model: this.lift.current_maintenance_company_id });
        }
    }

    /**
     * Opens the managing entity details modal
     */
    viewManagingEntity() {
        this.dialogService.open({ viewModel: ViewManagingEntityDetails, model: this.lift.managing_entity_id });
    }

    /**
     * Opens the owner details modal
     */
    viewOwner() {
        this.dialogService.open({ viewModel: ViewOwnerDetails, model: this.lift.current_owner_id });
    }

    /**
     * Opens the lift history modal
     */
    viewHistory() {
        this.dialogService.open({ viewModel: ViewLiftHistory, model: this.lift });
    }

    /**
     * Opens the lift certificates modal
     */
    viewCertificates() {
        this.dialogService.open({ viewModel: ViewLiftCertificates, model: this.lift });
    }

    /**
     * Checks if the lift is `approved`
     */
    isApproved() {
        return this.lift.status.id === LiftStatus.APPROVED;
    }

    /**
     * Checks if the lift is `rejected`
     */
    isRejected() {
        return this.lift.status.id === LiftStatus.REJECTED;
    }

    /**
     * Checks if the lift is `sealed`
     */
    isSealed() {
        return this.lift.status.id === LiftStatus.SEALED;
    }

    /**
     * Checks if the lift is `disassembled`
     */
    isDisassembled() {
        return this.lift.status.id === LiftStatus.DISASSEMBLED;
    }

}
