import { inject }                         from 'aurelia-framework';
import { CreateMaintenanceCompanyModal }  from 'modules/entities/maintenance-companies/create-modal/index';
import { DialogService }                  from 'aurelia-dialog';
import { LiftMaintenanceCompany }         from 'modules/lifts/models/lift-maintenance-company';
import { MaintenanceCompaniesRepository } from 'modules/entities/maintenance-companies/services/repository';

@inject(MaintenanceCompaniesRepository, DialogService)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param maintenanceCompaniesRepository
     * @param dialogService
     */
    constructor(maintenanceCompaniesRepository, dialogService) {
        this.maintenanceCompaniesRepository = maintenanceCompaniesRepository;
        this.dialogService                  = dialogService;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LiftMaintenanceCompany}
     */
    model() {
        let model = new LiftMaintenanceCompany();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the data form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.maintenance_company_id = {
            type:         'select2',
            key:          'maintenance_company_id',
            label:        'form.field.maintenance-company',
            size:         12,
            options:      [],
            remoteSource: this.maintenanceCompaniesRepository.active.bind(this.maintenanceCompaniesRepository),
            inputGroup:   {
                position: 'right',
                button:   {
                    title:   'form.button.create-new',
                    action:  () => this.createNewMaintenanceCompany(viewModel),
                    icon:    { class: 'icon-plus3' },
                    visible: viewModel.appContainer.authenticatedUser.can(['entities.maintenance-companies.manage', 'entities.maintenance-companies.create']),
                },
            },
        };

        this.closeButton = {
            type:       'submit',
            label:      'form.button.close',
            action:     () => viewModel.creationSchemaVisible = false,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-cross',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.add',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn bg-teal pull-right',
            },
            icon:       {
                attributes: {
                    class: 'icon-plus22',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            attributes: {
                class: 'form-wizard-actions ml-auto ml-2 mr-2',
            },
            actions: [
                this.closeButton,
                this.submitButton,
            ],
        };

        return [
            [this.maintenance_company_id],
            [this.buttons],
        ];
    }

    /**
     * Opens the maintenance company creation modal
     *
     * @param viewModel
     */
    createNewMaintenanceCompany(viewModel) {
        this.dialogService
            .open({ viewModel: CreateMaintenanceCompanyModal })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.maintenance_company_id.instance.fetchData().then(() => viewModel.model.maintenance_company_id = response.output);
                }
            });
    }

}
