import { bindable, customElement, inject } from 'aurelia-framework';
import { AppContainer }                    from 'resources/services/app-container';
import { BaseViewModel }                   from 'base-view-model';
import { LiftMaintenanceLogsRepository }   from 'modules/lifts/lifts/maintenance-logs/services/repository';

@customElement('lift-maintenance-logs')
@inject(AppContainer, LiftMaintenanceLogsRepository)
export class ListLiftMaintenanceLogs extends BaseViewModel {

    listingId = 'lifts-lift-maintenance-logs-listing';

    @bindable lift;
    repository;
    datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     */
    constructor(appContainer, repository) {
        super(appContainer);

        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'lifts.lifts.manage',
            'lifts.lifts.view',
        ]);
    }

    /**
     * Handles activate event
     */
    bind() {
        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:      {
                search: (criteria) => this.repository.search(this.lift.id, criteria),
            },
            destroy:         false,
            destroySelected: false,
            actions:         [],
            options:         [],
            buttons:         [],
            selectable:      false,
            sorting:         {
                column:    0,
                direction: 'desc',
            },
            columns:         [
                {
                    data:  'date',
                    name:  'maintenance_logs.date',
                    title: 'form.field.date',
                },
                {
                    data:  'type',
                    name:  'maintenance_log_types.name',
                    title: 'form.field.log-type',
                },
                {
                    data:  'description',
                    name:  'maintenance_logs.description',
                    title: 'form.field.description',
                },
                {
                    data:  'creator',
                    name:  'users.name',
                    title: 'form.field.registered-by',
                },
            ],
        };
    }

}
